
<template>

  <v-main style="background-color:#F2F3F8">

      <v-container>
 

        <v-row>
          <!-- <v-col cols="12" sm="1">
          </v-col> -->

       

          <!--메인:왼쪽 열  -->
          <v-col
            cols="12"
            sm="12">

            <v-sheet 
              rounded="lg"
              style="padding:20px"
              
              >

              <v-list >
                 <v-list-item>
                 
                   <v-img
                   max-height="50"
                   max-width="50"
                   src="https://ipfs.orelo.software/ipfs/QmayPvbgrJg14fAsneUbcHyaLuG6866vQyR4RKRnFBjMbK"></v-img>

                   <v-list-item-content>
                     <v-list-item-subtitle style="font-size:16px;margin-left:10px">Violet Garden</v-list-item-subtitle>
                    
                   </v-list-item-content>

                   <v-tooltip bottom color="black">
                     <template v-slot:activator="{ on, attrs }" >
                       <td   v-bind="attrs" v-on="on">
                         <v-img
                         max-height="20"
                         max-width="20"
                         :src="require('@/assets/BadgeEdenMember.png')"></v-img>
                       </td>
                     </template>
                     <span>Only Eden member can claim.</span><br>
                     
                   </v-tooltip>

                 </v-list-item>

              </v-list>
 
                <v-card
                    class="mx-auto rounded-lg"
                    
                    elevation="4"
                     
                  >
                    <v-card-text>
                       
                        <v-list >

                            <v-list-item>

                              <v-list-item-icon>
                                <v-icon color="grey">
                                  mdi-cards-heart-outline
                                </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-subtitle style="font-size:16px">Daily Reward</v-list-item-subtitle>
                                <!-- <v-list-item-title style="font-size:20px;font-weight:bold">$0.28</v-list-item-title> -->
                              </v-list-item-content>

                              
                              <h3 style="color:grey">50 VIO</h3>
 

                            </v-list-item>

                        </v-list>

                   



                      <div class="text--primary">
                        A daily reward for you being you.
                      </div>
                      <br>
                    
                    <!-- <span>{{info}}</span> -->

                      <span>Your VIO Balance : {{violetGardenBalance}}</span><br>
                      <!-- <span>Last Claim Date : {{getTimezoneDate(lastVGClaimDate)}}</span> -->

                      <!-- <span id="vb">Vio Balance : loading...</span><br>
                      <span id="vd">Last Claim Date : loading...</span> -->

                    </v-card-text>

                  

                    <v-card-actions>

                      <v-btn
                       outlined color="primary"
                       @click="anchorClaimVioletGarden"
                       :loading="isClaimVGLoading"
                       :disabled="!($store.getters.isAnchorLogined && canVGClaim )" 
                      >
                        {{violetGardenTokenClaimButtonMessage}}
                      </v-btn>
                       <v-btn
                        outlined color="primary"
                        :loading="isTradeVGLoading"
                        @click="anchorClaimAndTradeVioletGarden"
                       :disabled="!($store.getters.isAnchorLogined && canVGClaim)"
                      >
                        Claim+Trade
                      </v-btn>
                     
                    </v-card-actions>

                    
                  </v-card>




            </v-sheet>
          </v-col>
 
          <!--메인:오른쪽 열  -->
          <v-col
            cols="12"
            sm="12">

            <v-sheet 
              rounded="lg"
              style="padding:20px"
              >

              <v-list >
                            <v-list-item>
                            
                              <v-img
                              max-height="60"
                              max-width="60"
                              src="https://functions.eosn.io/v2/ipfs/QmfMW7xsjsTBYnUe89d6pJqLU44Uynp3zTmwmF1cwPiPch?size=500"></v-img>

                              <v-list-item-content>
                                <v-list-item-subtitle style="font-size:16px;margin-left:10px">Pomelo Robot Potion</v-list-item-subtitle>
                               
                              </v-list-item-content>

                              

                            </v-list-item>

              </v-list>
 
                <v-card
                    class="mx-auto rounded-lg"
                  
                    elevation="4"
                     
                  >
                    <v-card-text>
                       
                        
                    <br>
                     <div class="text--primary">
                         Robot Potion ({{diamondPotionCount}}x)
                      </div>



                      <div class="text--primary">
                       Total Mana Remain : {{diamondPotionRemainManaTotal}}
                      </div>
                      <br>
                    
                     <span>Holding this Robot Potion allows you to claim Moon Bots until your mana is depleted.</span><br>

                    </v-card-text>

                    

                    <v-card-actions>

                     <v-btn
                     outlined color="primary"
                       @click="anchorPomeloDiamondPotionClaim"
                       :loading="isClaimPomeloLoading"
                       :disabled="!($store.getters.isAnchorLogined && canDiamondPotionClaim )" 
                      >
                        {{diamondPotionClaimButtonMessage}}
                      </v-btn>
                     
                    </v-card-actions>

                    
                  </v-card>




            </v-sheet>
          </v-col>

             <!--메인:왼쪽 열  -->
          <v-col v-if="false"
            cols="12"
            sm="12">

            <v-sheet 
            
              rounded="lg"
              style="padding:20px"
               >

              <v-list >
                <v-list-item>
                  <v-icon color="orange darken-2" x-large>
                    mdi-gamepad-right
                  </v-icon>

                  <v-list-item-content>
                    <v-list-item-subtitle style="font-size:16px;margin-left:10px">EOS Quest</v-list-item-subtitle>
                   
                  </v-list-item-content>
 

                </v-list-item>

              </v-list>
 
              <v-card
                    class="mx-auto rounded-lg"
                    elevation="4"
                     
                  >
                    <v-card-text>
                       
                        <v-list >

                            <v-list-item>

                              <v-list-item-icon>
                                <v-icon color="grey">
                                  mdi-gamepad-circle-right
                                </v-icon>
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-subtitle style="font-size:16px">Daily Reward</v-list-item-subtitle>
                                <!-- <v-list-item-title style="font-size:20px;font-weight:bold">$0.20</v-list-item-title> -->
                              </v-list-item-content>

                              
                              <h3 style="color:grey">10 QUEST</h3>
 

                            </v-list-item>

                        </v-list>

                   



                      <div class="text--primary">
                        A daily reward
                      </div>

                      <br><span>Your QUEST Balance : N/A</span><br>

                    </v-card-text>

                     

                    <v-card-actions>

                        <v-btn
                        outlined
                        color="primary"
                        disabled
                        
                      >
                        Comming soon
                      </v-btn>
                     
                    </v-card-actions>

                    
              </v-card>

            </v-sheet>
          </v-col>

        </v-row>

      </v-container>


    </v-main>

</template>

 
<script>



import Axios from 'axios';


export default {

  data() {
    return {
      isClaimVGLoading:false,
      lastVGClaimDate:'',
      violetGardenBalance:'Login needed',
      violetGardenTokenClaimButtonMessage:'Login needed',
      violetGardenSearchAccount:'',
      canVGClaim:false,
      isTradeVGLoading:false,
      txID:'',
      timeZoneDiff:0,
      workerVIODateRemain:null,

      workerPomeloDateRemain:null,
      isClaimPomeloLoading:false,
      diamondPotionClaimButtonMessage:'Login needed',
      canDiamondPotionClaim:false,
      lastPomeloClaimDate:0,
      diamondPotionCount:0,
      diamondPotionRemainManaTotal:0
    }
  },
  watch: {
  '$store.state.mainAnchorLoginName': function() {
   
      if(this.$store.state.mainAnchorLoginName == "")
      {
         //만약 VIO 날짜 체크 중이면 interval중지하기
         if(this.workerVIODateRemain != null)
         {
           clearInterval(this.workerVIODateRemain);
           this.workerVIODateRemain = null;
         }

         this.lastVGClaimDate = '';
         this.violetGardenBalance ='Login needed';
         this.violetGardenTokenClaimButtonMessage='Login needed';
         this.canVGClaim=false;

         //pomelo
         if(this.workerPomeloDateRemain != null)
         {
           clearInterval(this.workerPomeloDateRemain);
           this.workerPomeloDateRemain = null;
         }

         this.lastPomeloClaimDate = 0;
         this.diamondPotionClaimButtonMessage='Login needed';
         this.canDiamondPotionClaim=false;

      }
      else{
        //로그인 되었으면 에덴멤버인지 확인후에 프로세스 시작하기
 
        if(this.workerVIODateRemain == null)
        {
          this.getVioletGardenVIOInfoWithAjax();
        }

        if(this.workerPomeloDateRemain == null)
        {
          this.getPomeloInfoWithAjax();
        }
         
      }
    }
  },
  methods: {

  anchorClaimVioletGarden(){
      
      this.isClaimVGLoading = true;
      const action = {
                account: 'labelaarbaro',
                name: 'checkin',
                authorization: [this.$store.state.mainAnchorSession.auth],
                data: {
                    owner: this.$store.state.mainAnchorSession.auth.actor
                }
            }
       
       this.$store.state.mainAnchorSession.transact({action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        alert(error);
       })
      .finally(()=> { 
        this.isClaimVGLoading = false; 
        
        this.getVioletGardenVIOInfoWithAjax();
        
        });
 
    },

  anchorClaimAndTradeVioletGarden()
  {
      this.isTradeVGLoading = true;
      const action = [ 
                {
                  account: 'labelaarbaro',
                  name: 'checkin',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                      owner: this.$store.state.mainAnchorSession.auth.actor
                  }
                },
                {
                  account: 'labelaarbaro',
                  name: 'withdraw',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: { owner: this.$store.state.mainAnchorSession.auth.actor}
                },
                {
                  account: 'violettokenn',
                  name: 'transfer',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                      from: this.$store.state.mainAnchorSession.auth.actor,
                      to: 'orelonetwork',
                      quantity: '50.0000' + ' VIO',
                      memo: '1,orelo.trade:EOSVIO EOS,0.0001,' + this.$store.state.mainAnchorSession.auth.actor
                  }
                }
                
                ]; 

       this.$store.state.mainAnchorSession.transact({actions:action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        //alert(error);
       })
      .finally(()=> { 
        
        this.isTradeVGLoading = false; 

        this.getVioletGardenVIOInfoWithAjax();
        
        });
  },

  anchorTradeVioletGarden()
  {
      this.isTradeVGLoading = true;
      const action = [
                {
                  account: 'labelaarbaro',
                  name: 'withdraw',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: { owner: this.$store.state.mainAnchorSession.auth.actor}
                },
                {
                  account: 'violettokenn',
                  name: 'transfer',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                      from: this.$store.state.mainAnchorSession.auth.actor,
                      to: 'orelonetwork',
                      quantity: '50.0000' + ' VIO',
                      memo: '1,orelo.trade:EOSVIO EOS,0.0001,' + this.$store.state.mainAnchorSession.auth.actor
                  }
                }
                
                ]; 

       this.$store.state.mainAnchorSession.transact({actions:action}).then((result) => 
       {
         this.txID = result.processed.id;
         //alert(this.txID);
       })
       .catch((error) => {
        //this.requestError = error
        //alert(error);
       })
      .finally(()=> { 
        
        this.isTradeVGLoading = false; 

        this.getVioletGardenVIOInfoWithAjax();
        
        });
  },
  anchorPomeloDiamondPotionClaim()
  {
      this.isClaimPomeloLoading = true;

      // const action = [
      //           {
      //             account: 'cpu.pomelo',
      //             name: 'noop',
      //             authorization: [
      //               {
      //                 actor:'cpu.pomelo',
      //                 permission:'cosign'
      //               },
      //               this.$store.state.mainAnchorSession.auth
      //               ],
      //             data: { referrer: 'harvest'}
      //           },
      //           {
      //             account: 'mine.gems',
      //             name: 'claim',
      //             authorization: [this.$store.state.mainAnchorSession.auth],
      //             data: {
      //                 owner: this.$store.state.mainAnchorSession.auth.actor,
      //                 template_id: '6656',
      //             }
      //           }
                
      //           ]; 

      const action = [
                {
                  account: 'mine.gems',
                  name: 'claim',
                  authorization: [this.$store.state.mainAnchorSession.auth],
                  data: {
                      owner: this.$store.state.mainAnchorSession.auth.actor,
                      template_id: '7924',
                  }
                }
                
                ]; 

       this.$store.state.mainAnchorSession.transact({actions:action}).then((result) => 
       {
         this.txID = result.processed.id;
       
       })
       .catch((error) => {
      
       })
      .finally(()=> { 
        
        this.isClaimPomeloLoading = false; 

        this.getPomeloInfoWithAjax();
        
        });
  },
  getVioletGardenVIOInfoWithAxios()
      {
        // Axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded' 
        // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        Axios.post('http://api.eosflare.io/v1/chain/get_table_rows', 
         {
            json:true,
            code:"labelaarbaro",
            scope:"labelaarbaro",
            table:"users",
            lower_bound: this.$store.state.mainAnchorSession.auth.actor,
            index_position:1,
            limit:1
        }, { withCredentials: false } 
        
        )
        .then(res=> {

          console.log(res);

          var resData = res.data.rows;

          if(resData.length ==0){
            //데이터가 없을때
          }
          else{

            //데이터가 있을때
            this.lastVGClaimData = resData[0].last_claim;
            this.violetGardenBalance =  resData[0].balance;
          }

          //2021-12-31T05:41:48.500
          
          //           {
          //     "rows": [
          //         {
          //             "account": "branhong.gm",
          //             "balance": "0.0000 VIO",
          //             "last_claim": "2021-12-30T04:02:58.500",
          //             "rec": 0,
          //             "veri": "labelaarbaro"
          //         }
          //     ],
          //     "more": true,
          //     "next_key": "4455525484908130528",
          //     "next_key_bytes": ""
          // }

         
          // if(res.data.err_code != 0)
          // {
          //   this.isDialogIconSuccess = false;
          //   this.dialogTitle = "AccountInfo Load Fail";
          //   this.dialogMessage = res.data.err_msg;
          //   this.dialog = true;
          //   return;
          // }
  
       

        })
        .catch(err => {
          // this.isDialogIconSuccess = false;
          // this.dialogTitle = "AccountInfo Load Fail";
          // this.dialogMessage = err;
          // this.dialog = true;
        });
         
      },
     getVioletGardenVIOInfo()
      {

        //성공 하지만 바인딩이 제대로 안됨
        //DOM을 찾아서 업데이트 해줘야함..
 
        var data = JSON.stringify(
          
          {
            "json":true,
            "code":"labelaarbaro",
            "scope":"labelaarbaro",
            "table":"users",
            "key_type":"name",
            "lower_bound":this.$store.state.mainAnchorSession.auth.actor,
            "index_position":1,
            "limit":1}
          );
 
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = false;

        xhr.addEventListener("readystatechange", function () {
        if (this.readyState === this.DONE) {

            var resData = JSON.parse(this.responseText);

            if(resData.rows.length ==0){
            //데이터가 없을때

            }
            else{
              this.$store.state.dailyTokenVIOBalance  = resData.rows[0].balance;
              this.$store.state.dailyTokenVIODate = resData.rows[0].last_claim;
            }
 
        }
        });

        xhr.open("POST", "https://eos.greymass.com/v1/chain/get_table_rows");
        xhr.send(data);

      },

      getVioletGardenVIOInfoWithAjax() {

      this.clearVIOTimer();

      //this.info = "Requesting ...";

      var data = JSON.stringify(
          
          {
            "json":true,
            "code":"labelaarbaro",
            "scope":"labelaarbaro",
            "table":"users",
            "key_type":"name",
            "lower_bound":this.$store.state.mainAnchorSession.auth.actor,
            "index_position":1,
            "limit":1}
          );

      this.violetGardenSearchAccount = this.$store.state.mainAnchorSession.auth.actor;

      var rq = new XMLHttpRequest();
      rq.withCredentials = false;

      rq.onreadystatechange = function(vm) {
        if (this.readyState === XMLHttpRequest.DONE) {

         
          if (this.status === 200) {
            //vm.info = this.responseText;

           
            var resData = JSON.parse(this.responseText);
            
            if(resData.rows.length ==0){
            //데이터가 없을때
            

            }
            else{

              if(resData.rows[0].account != vm.violetGardenSearchAccount)
              {
                //에덴멤버가 아닐경우
                vm.canVGClaim = false;
                vm.violetGardenTokenClaimButtonMessage = "Not Eden Member"
              }
              else{
                //에덴멤버일경우
              vm.violetGardenBalance  = resData.rows[0].balance;
              vm.lastVGClaimDate  = new Date(resData.rows[0].last_claim);
              //vm.lastVGClaimDateString = resData.rows[0].last_claim;

             
              vm.startVIOCanClaimRemainTime();
              }
 
            
            }


          } else 
          {
            // vm.info = "Request Failed";
          }
        }
      }.bind(rq, this);

      rq.open("POST", "https://eos.greymass.com/v1/chain/get_table_rows");
      rq.send(data);
    },

    getPomeloInfoWithAjax() {

      this.clearPomeloTimer();
      //console.log(this.$store.state.mainAnchorLoginName);
 
      Axios.get('https://functions.eosn.io/v2/gems/mine/claim?chain=eos&owner='+this.$store.state.mainAnchorLoginName+'&template_id=7924')
        .then(res=> {
           
          if(res.status != 200)
          {
            this.diamondPotionClaimButtonMessage = "Not available";
            return;
          }

          var resData = res.data;
          if(resData.assets.length == 0){

            this.diamondPotionClaimButtonMessage = "No Potions";
            return;

          }
          
          this.diamondPotionRemainManaTotal= resData.total;

          if( this.diamondPotionRemainManaTotal == 0)
          {
             this.diamondPotionClaimButtonMessage = "No Mana";
            return;
          }

          this.diamondPotionCount = resData.assets.length;
          this.lastPomeloClaimDate=resData.next_claim;

          this.startPomeloCanClaimRemainTime();

        })
        .catch(err => {
         
        })
        
      },
       
 

    getTimezoneDate(date)
      {
        if(date == "") return "loading...";
        //console.log(date);
        var cDate =new Date(date);
        //console.log(cDate);
        cDate.setHours(cDate.getHours() + this.timeZoneDiff);
        return cDate.getFullYear()+"-"+(cDate.getMonth()+1)+"-"+cDate.getDate()+ " "+cDate.getHours()+":"+cDate.getMinutes()+":"+cDate.getSeconds();
    },
 
    fortmatResponse(res) {
       return JSON.stringify(res, null, 2);
    },
 
    startVIOCanClaimRemainTime () {

		  this.workerVIODateRemain = setInterval(() => {
			//this.$store.dispatch('RETRIEVE_DATA_FROM_BACKEND')

      //날짜 체크해서 남은 시간을 표출해준다.

      //alert(this.lastVGClaimDate);
      if(this.lastVGClaimDate == '') return;

       var lastClaimDate =new Date(this.lastVGClaimDate);
       const canClaimDate = lastClaimDate.setHours(lastClaimDate.getHours() + 24);
       var nowDate = new Date();
       const localdDate = nowDate.setHours(nowDate.getHours() - this.timeZoneDiff);

      if(canClaimDate > localdDate){
        //클레임 못하게 처리
        this.canVGClaim = false;

        var msec = canClaimDate-localdDate;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;

        if(hh.toString().length == 1) hh = "0" + hh.toString();
        if(mm.toString().length == 1) mm = "0" + mm.toString();
        if(ss.toString().length == 1) ss = "0" + ss.toString();

        this.violetGardenTokenClaimButtonMessage = hh +":" + mm +":" + ss ;

      }
      else{
       //클레임 가능하게 처리
       this.canVGClaim = true;
       this.violetGardenTokenClaimButtonMessage = "50 VIO"
      }
      
		}, 1000)
	},
  startPomeloCanClaimRemainTime () {

		  this.workerPomeloDateRemain = setInterval(() => {
			//this.$store.dispatch('RETRIEVE_DATA_FROM_BACKEND')

      //날짜 체크해서 남은 시간을 표출해준다.

      //alert(this.lastVGClaimDate);
      if(this.lastPomeloClaimDate == 0) return;
        
       const canClaimDate = new Date(this.lastPomeloClaimDate*1000);
       var nowDate = new Date();
       const localdDate = nowDate.setHours(nowDate.getHours());
 
      if(canClaimDate > localdDate){
        //클레임 못하게 처리
        this.canDiamondPotionClaim = false;

        var msec = canClaimDate-localdDate;
        var hh = Math.floor(msec / 1000 / 60 / 60);
        msec -= hh * 1000 * 60 * 60;
        var mm = Math.floor(msec / 1000 / 60);
        msec -= mm * 1000 * 60;
        var ss = Math.floor(msec / 1000);
        msec -= ss * 1000;

        if(hh.toString().length == 1) hh = "0" + hh.toString();
        if(mm.toString().length == 1) mm = "0" + mm.toString();
        if(ss.toString().length == 1) ss = "0" + ss.toString();

        this.diamondPotionClaimButtonMessage = hh +":" + mm +":" + ss ;

      }
      else{
       //클레임 가능하게 처리
       this.canDiamondPotionClaim = true;
       this.diamondPotionClaimButtonMessage = "Claim All"
      }
      
		}, 1000)
	},
  clearVIOTimer()
  {
    if(this.workerVIODateRemain != null) 
    {
      clearInterval(this.workerVIODateRemain);
      this.workerVIODateRemain = null;
    }
  },

  clearPomeloTimer()
  {
    if(this.workerPomeloDateRemain != null) 
    {
      clearInterval(this.workerPomeloDateRemain);
      this.workerPomeloDateRemain = null;
    }
  },
},
 
  computed:{

    isVIOCanTrade(){

      if(this.violetGardenBalance == "loading..." || this.violetGardenBalance == "Login needed"|| this.violetGardenBalance == "0.0000 VIO")
      {
        return false;
      }
      else{
        return true;
      }
    },

  },
   mounted() {

    //console.log('daily mounted');

    this.timeZoneDiff = -(new Date().getTimezoneOffset()/60); //new Date().getTimezoneOffset()/60는 한국은 -9 나오는데 9를 더해야 하므로 -를 앞에 해준다.
 

    if(this.$store.state.mainAnchorLoginName != '')
    {
         this.getVioletGardenVIOInfoWithAjax();
         this.getPomeloInfoWithAjax();
    }

     this.$vuetify.goTo(0);
    
  },
  beforeDestroy () {
    // console.log('beforeDestroy');
    // if(this.workerVIODateRemain != null) 
    // {
    //   clearInterval(this.workerVIODateRemain);
    //   this.workerVIODateRemain = null;
    // }
    this.clearVIOTimer();
    this.clearPomeloTimer();
},

}
</script>

 